<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="dirty"
    max-width="800px"
    transition="dialog-transition"
  >
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Player Search</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" fab small @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <v-row dense>
          <v-col cols="12"  v-if="!addNew">
            <player-lookup
              @player-selected="gotoPlayer"
              :clearOnSelect="true"
              ref="search"
              :allowAdd="allowAdd"
              @manual-click="addNewPlayer"
            ></player-lookup>
          </v-col>
          <v-col cols="12" v-if="user && user.vbl">
            <v-text-field
              label="Player Id"
              v-model="pid"
            >
              <template v-slot:append-outer>
                <v-btn
                  color="success white--text"
                  small
                  fab
                  @click.stop="gotoPlayer({id: pid}); pid = null"
                  :disabled="!pid"
                  type="number"
                  dense
                >Go</v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="addNew">
            <person-form
              :player="editablePlayer"
              :fields="['phone', 'email', 'fullAddress', 'dob']"
              :requiredFields="['name', 'gender']"
            ></person-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end" v-if="addNew">
        <v-fab-transition>
          <v-btn
            :key="dirty"
            color="success white--text"
            fab small
            :disabled="!dirty"
            @click.stop="save"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayerLookup from '@/components/Player/Search/PlayerLookup'
import PlayerProfile from '@/classes/PlayerProfile'
const PersonForm = () => import('@/components/Forms/PersonForm.vue')

export default {
  props: ['open', 'emitter', 'allowAdd'],
  data () {
    return {
      search: null,
      loading: false,
      players: [],
      headers: [
        { text: 'Id', value: 'id', align: 'left' },
        { text: 'First Name', value: 'firstName', align: 'left' },
        { text: 'Last Name', value: 'lastName', align: 'left' },
        { text: 'City', value: 'city', align: 'left' },
        { text: 'State', value: 'state', align: 'left' },
        { text: 'AAU', value: 'aauNumber', align: 'left' },
        { text: 'AVPA', value: 'avpAmericaNumber', align: 'left' },
        { text: 'AVP', value: 'avpNumber', align: 'left' }
      ],
      pagination: { sortBy: 'id', rowsPerPage: 50 },
      editablePlayer: {
        id: 0
      },
      selectedPlayer: {
        id: 0
      },
      dialog: false,
      edit: false,
      saving: false,
      compareTo: false,
      addNew: false,
      pid: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    dirty () {
      return this.editablePlayer && this.selectedPlayer && JSON.stringify(this.editablePlayer) !== JSON.stringify(this.selectedPlayer)
    },
    dto () {
      if (!this.dirty) return null
      const result = { id: this.editablePlayer.id }
      Object.keys(this.editablePlayer).forEach(k => {
        const a = this.selectedPlayer[k]
        const b = this.editablePlayer[k]
        if (a !== b) {
          result[k] = b
        }
      })

      return result
    }
  },
  methods: {
    addNewPlayer () {
      this.selectPlayer(new PlayerProfile())
      this.addNew = true
    },
    gotoPlayer (player) {
      if (this.emitter) {
        this.$emit('player-selected', player)
      } else {
        this.$router.push(`/player/${player.id}`)
      }
      this.dialog = false
    },
    loadPlayer (player) {
      this.loading = true
      this.$VBL.get.player(player.id)
        .then(r => {
          this.selectPlayer(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    selectPlayer (player) {
      this.selectedPlayer = Object.assign({}, player)
      this.editablePlayer = Object.assign({}, player)
    },
    cancel () {
      this.selectedPlayer = { id: 0 }
      this.editablePlayer = { id: 0 }
      this.dialog = false
      this.addNew = false
      this.$emit('closed')
    },
    save () {
      this.saving = true
      this.$VBL.patch.player(this.dto)
        .then(r => {
          console.log(r.data)
          Object.assign(this.selectedPlayer, r.data)
          this.gotoPlayer(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.saving = false
          this.cancel()
        })
    }
  },
  watch: {
    dialog: function (n, o) {
      if (o && !n) this.cancel()
    },
    open: function (val) {
      if (val) {
        this.dialog = true
      }
    }
  },
  components: {
    PlayerLookup,
    PersonForm
  }
}
</script>

<style>

</style>
